

    <p>guide works!</p>
    <div class="container" style="height:1300px;">
        <div>
            <div class="col text-center">
                <h2>This Navbar is fixed</h2>
                <h5>It will always stay visible on the top, even when you scroll down</h5>
                <br>
                <p>Full page intro with background image will be always displayed in full screen mode, regardless of device </p>
            </div>
        </div>
    </div>

