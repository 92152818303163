import { Component, OnInit } from '@angular/core';
import { data } from '../../mock';
import { RestaurantService } from '../../services/restaurant.service';
import { Restaurant } from 'src/app/interfaces/restaurant';
import { map } from 'rxjs/Operators';
import { Rating } from 'src/app/interfaces/rating';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-restaurants',
  templateUrl: './restaurants.component.html',
  styleUrls: ['./restaurants.component.less']
})
export class RestaurantsComponent implements OnInit {
  clickMessage: string;
  data = data;
  restaurants: Restaurant[];

  constructor(
    private restaurantService: RestaurantService,
    public afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.restaurantService.getAllRestaurants().subscribe(data => {
      this.restaurants = data.map(p => {
        return p
      });
    })

    // this.restaurantService.getAllRestaurants()
    //   .pipe(
    //     map(action => action.pipe(
    //       map(data => {
    //         this.restaurants = data.map(c => {
    //           return c as Restaurant
    //         })

    //       }
    //       )
    //     )
    //     )
    //   );

    // var aa = this.restaurantService.getAllRestaurants();

  }

  getRandomItem(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  async onSubmit(form: NgForm) {
    const { restaurantId } = form.value;
    var rating: any;
    // var ratingPromises = [];
    var uid;
    var displayName;
    // await this.afAuth.user.subscribe(data => {

    // }

    // );

    uid = 'd2j0AQ1dZDTq6WP4ALVWqZBT0IM2';
    displayName = 'Keanu Miu';
    for (var r = 0; r < 5; r++) {
      var _rating = this.data.ratings[
        parseInt((this.data.ratings.length * Math.random()).toString())
      ].rating;

      // ratingPromises.push(this.restaurantService.addRating(restaurantId, {
      //   userName: displayName,
      //   userId: uid,
      //   rating: _rating,
      //   // createOn: timestamp,
      //   // updateAt: timestamp
      // }));

      this.restaurantService.addRating(restaurantId, {
        userName: displayName,
        userId: uid,
        rating: _rating,
        // createOn: timestamp,
        // updateAt: timestamp
      })
      
    }
    

    // for (var r = 0; r < 5 * Math.random(); r++) {
    //   var _rating = this.data.ratings[
    //     this.data.ratings.length * Math.random()
    //   ];
    //   rating.userName = displayName;
    //   rating.timestamp = new Date();
    //   rating.userId = uid;
    //   ratingPromises.push(this.restaurantService.addRating(restaurantId, rating));
    // }
    // return Promise.all(ratingPromises);
  }



  onClickMe() {

    for (var i = 0; i < 20; i++) {
      var name =
        this.getRandomItem(this.data.words) +
        ' ' +
        this.getRandomItem(this.data.words);
      var category = this.getRandomItem(this.data.categories);
      var city = this.getRandomItem(this.data.cities);
      var price = Math.floor(Math.random() * 4) + 1;
      var photoID = Math.floor(Math.random() * 22) + 1;
      var photo = 'https://storage.googleapis.com/firestorequickstarts.appspot.com/food_' + photoID + '.png';
      var numRatings = 0;
      var avgRating = 0;

      var result = this.restaurantService.push(
        {
          name: name,
          category: category,
          price: price,
          numRatings: numRatings,
          avgRating: avgRating,
          photo: photo
        }
      )

    }

    this.clickMessage = 'You are my hero!';
  }

}
