import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/Operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less'],
  providers:[AuthService],
})
export class ProfileComponent implements OnInit {

  constructor(
    private authservice: AuthService,
    public afAuth: AngularFireAuth
  ) {
    var user = afAuth.user.subscribe(map=>{
      // map.displayName

    });

  }

  ngOnInit(): void {
  }

  logout() {
    this.authservice.logout();
  }

}
