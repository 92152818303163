<mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-dark primary-color scrolling-navbar"
  [containerInside]="false">
  <logo>
    <a class="logo navbar-brand" [routerLink]="['/guide']">
      <strong>Heabit</strong>
    </a>
  </logo>
  <links>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" [routerLink]="['/guide']">Guide <span class="sr-only">(current)</span></a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="#">Features</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Pricing</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Opinions</a>
      </li> -->

    </ul>
    <ul class="navbar-nav nav-flex-icons">
      <li class="nav-item">
        <a class="nav-link"><i class="fab fa-twitter"></i></a>
      </li>
      <li class="nav-item">
        <a class="nav-link"><i class="fab fa-instagram"></i></a>
      </li>
    </ul>

    <ul class="navbar-nav nav-flex-icons" *ngIf="afAuth.user | async as user else loginTmpl">
      <li class="nav-item">
        <a [routerLink]="['/cart']" class="nav-link"><i
            class="material-icons">account_circle</i>{{ user.displayName }}</a>

      </li>
      <li class="nav-item">
        <a [routerLink]="['/cart']" class="nav-link" (click)="logout()"><i class="material-icons">exit_to_app</i>Sign
          out</a>
      </li>
    </ul>
    <ng-template #loginTmpl>
      <ul class="navbar-nav nav-flex-icons">
        <li class="nav-item">
          <a [routerLink]="['/login']" class="nav-link"><mdb-icon fas icon="user-alt"></mdb-icon>Login / Sign Up</a>
        </li>
      </ul>
    </ng-template>
  </links>
