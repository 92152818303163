import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private location: Location,
  ) {
    this.setMeta([
      { name: 'og:url', content: `${environment.host}${location.path(true)}` },
      { name: 'og:type', content: `website` },
      { name: 'og:site_name', content: `Heabit` }
    ])
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} - Heabit`);
  }

  setMeta(tags: any) {
    this.meta.addTags(tags);
  }

}
