import { Component, OnInit } from '@angular/core';
import {HeaderService} from '../../services/header.service'

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.less'],
})
export class GuideComponent implements OnInit {

  constructor(
   private header: HeaderService,
  ) { 
    this.header.setTitle( 'Featured Guilde' );
    this.header.setMeta([
      { name: 'description', content: 'all guilde for what you needs' }
  ]);
  }

  ngOnInit(): void {
  }


}
