<p>restaurants works!</p>

<button (click)="onClickMe()">Click me!</button>
{{clickMessage}}

<form class="text-center border border-light p-5 rounded w-25" action="#!" #form="ngForm" (submit)="onSubmit(form)">
    <input type="text" name="restaurantId" class="form-control" ngModel>
    <button class="btn btn-info my-4 btn-block" type="submit">
        Update
    </button>
</form>

<div class="card" *ngFor="let restaurant of restaurants">
    <div>{{ restaurant.id }}</div>
    <div>{{ restaurant.avgRating }}</div>
    <div>{{ restaurant.name }}</div>
    <div>{{ restaurant.numRatings }}</div>
    <div>{{ restaurant.price }}</div>
    <img src="{{restaurant.photo}}" class="rounded" alt="Cinque Terre">
</div>