import { Injectable } from '@angular/core';
import { FirestoreService } from '../models/firestore-service.model';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import { Restaurant } from '../interfaces/restaurant';
import * as firebase from 'firebase/app';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Rating } from '../interfaces/rating';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
  private db: FirestoreService<Restaurant>;
  // private collection: AngularFirestoreCollection<Restaurant>;
  private collectionName = 'restaurants';

  xxx$: Restaurant[];
  items$: Observable<Restaurant[]>;
  sizeFilter$: BehaviorSubject<string | null>;
  colorFilter$: BehaviorSubject<string | null>;

  constructor(private afs: AngularFirestore) {
    this.db = new FirestoreService<Restaurant>(afs, this.collectionName);
    this.sizeFilter$ = new BehaviorSubject(null);
    this.colorFilter$ = new BehaviorSubject(null);
  }

  push(restaurant: any) {
    const timestamp = this.db.timestamp;
    return this.db.add({
      ...restaurant,
      createOn: timestamp,
      updateAt: timestamp
    });
  }
  
  addRating (restaurantID, rating : any)
  {
    var collection = this.afs.firestore.collection(this.collectionName);
    var document = collection.doc(restaurantID);
    var newRatingDocument = document.collection('ratings').doc();

    return this.afs.firestore.runTransaction(function(transaction){
      return transaction.get(document).then(function(doc){
        var data = doc.data() as Restaurant;

        var newAverage = (data.numRatings * data.avgRating + rating.rating) / (data.numRatings + 1);

        transaction.update(document, {numRatings: data.numRatings + 1,
        avgRating:newAverage
      });
      return transaction.set(newRatingDocument,rating);
      });
    });

  };

  getAllRestaurants(){
    // return this.afs.collection(this.collectionName, ref => ref.orderBy('price', 'desc').limit(10))
    // .snapshotChanges()
    // .pipe(
    //   map(actions => actions.map(a => {
    //     const data = a.payload.doc.data() as Restaurant;
    //     const id = a.payload.doc.id;
    //     return <Restaurant>{ id, ...data };
    //   }))
    // );

    return this.afs.collection(this.collectionName, ref => 
      {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('category', '==', 'Pizza');
        query = query.orderBy('price', 'desc').limit(10);
        return query;
      })
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Restaurant;
        const id = a.payload.doc.id;
        return <Restaurant>{ id, ...data };
      }))
    );

    // combineLatest<any[]>(
    //   this.sizeFilter$,
    //   this.colorFilter$
    // ).pipe(
    //   map(([size, color]) =>
    //     this.afs.collection<Restaurant>(this.collectionName, ref => {
    //       let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    //       // if (size) { query = query.where('avgRating', '==', size) };
    //       // if (color) { query = query.where('price', '==', color) };
    //       return query;
    //     })
    //       .snapshotChanges()
    //       .pipe(
    //         map(actions => 
    //           this.xxx$ = actions.map(a => {
    //           const data = a.payload.doc.data() as Restaurant;
    //           const id = a.payload.doc.id;
    //           return <Restaurant>{ id, ...data };
    //         }))
    //       )
    //   )
    // );
    // return this.xxx$;

  }

  filterBySize(size: string | null) {
    this.sizeFilter$.next(size);
  }
  filterByColor(color: string | null) {
    this.colorFilter$.next(color);
  }
  get timestamp() {
    return this.db.timestamp;
}
}
